import { useRef } from 'react';
import { MotionValue } from 'framer-motion';
import { useSelector, useDispatch } from 'react-redux';
import theme from '@client/css-modules/HomeSubpageTrackOrBuyHome.css';
import { getIsFeatureEnabled } from '@client/store/selectors/enabled-features.selectors';
import { View } from '@client/routes/constants';
import { PARENT_EVENTS } from '@client/store/analytics-constants';
import { reportEvent } from '@client/store/actions/analytics.actions';
import TrackOrBuyHomeLayout from '@client/components/TrackOrBuyHomeLayout';

import BuyHomeImage from '@client/assets/images/homepage-track-home-1.jpg';
import ClaimHomeImage from '@client/assets/images/homepage-track-home-2.jpg';
import { useHomepageElementAnimationMetrics } from '@client/hooks/use-homepage-element-animation-metrics.hooks';

type Props = {
  scrollPosition: MotionValue<number>;
};

const STARTING_POSITION_OFFSET = 250;
const HomeSubpageTrackOrBuyHome: React.FC<Props> = ({ scrollPosition }) => {
  const dispatch = useDispatch();
  const isHomeownerEnabled = useSelector(getIsFeatureEnabled('homeowner'));
  const ref = useRef<HTMLDivElement | null>(null);
  const { y, opacity } = useHomepageElementAnimationMetrics(
    ref,
    scrollPosition,
    STARTING_POSITION_OFFSET
  );
  const animate = {
    y,
    opacity,
  };

  return (
    <div className={theme.HomeSubpageTrackOrBuyHome} ref={ref}>
      <div className={theme.HighZoomScrollableWrapper}>
        <TrackOrBuyHomeLayout
          animate={animate}
          dataHcPrefix="buy-home-modal"
          animationDelay={0.2}
          title="Buying a home"
          buttonText="Search homes"
          ctaLink={View.SEARCH}
          image={BuyHomeImage}
          imageAlt="A building"
          onClick={() =>
            dispatch(
              reportEvent('click_cta_homepage_buyer', PARENT_EVENTS.CLICK_CTA)
            )
          }
        >
          Search homes for sale and filter by price, neighborhood, school
          ratings, and more. Find the perfect home that fits your needs.
        </TrackOrBuyHomeLayout>
        {isHomeownerEnabled && (
          <TrackOrBuyHomeLayout
            animate={animate}
            dataHcPrefix="your-home-dash-modal"
            animationDelay={0.3}
            title="Your homeowner dashboard"
            buttonText="See my home"
            ctaLink={View.HOMEOWNER}
            desktopImagePosition="left"
            image={ClaimHomeImage}
            imageAlt="A house"
            onClick={() =>
              dispatch(
                reportEvent('click_cta_homepage_owner', PARENT_EVENTS.CLICK_CTA)
              )
            }
          >
            See your home's value, equity, and what a home renovation would do
            to your value. Claim your home and access these features and more.
          </TrackOrBuyHomeLayout>
        )}
      </div>
    </div>
  );
};

export default HomeSubpageTrackOrBuyHome;
